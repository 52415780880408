<template>
  <div role="banner">
    <div id="banner" :class="{hide : $route.path.endsWith('main')}">
      <div>
        <img alt="IELTS" src="@assets/images/banner/IELTSlogo.png" class="ieltslogo" width="206px" height="60px"/>
        <img alt="IELTS Partners" src="@assets/images/banner/IELTSpartners.jpg" class="ieltspartners"/>
      </div>
    </div>
    <div id="test-banner">

      <p id="candidate" :class="{show : !$route.path.endsWith('confirm') }">

          <span id="candidate-name-label">
            Candidate name:
          </span>
        <strong aria-labelled-by="candidate-name-label" class="candidate-name">
          XXXX XXXXXXX
        </strong>
        <span> - </span>
        <span id="candidate-number-label">
            Candidate number:
          </span>
        <strong aria-labelled-by="candidate-number-label" class="candidate-number">
          123456
        </strong>
      </p>
      <countdown :end-time="new Date().getTime() + 60 * 60 * 1000"
                 @finish="finish"
                 ref="countdown"
                 @mouseover.native="show(`timeDetail`)"
                 @mouseout.native="close(`timeDetail`)"
                 v-if="$route.path.endsWith('main')">
        <template v-slot:process="{ timeObj }">
          <p id="timer" :class="{ alert : $refs.countdown.timeObj.ceil.m <= 5 }" @click=" () => countdown = !countdown">
            <template v-if="countdown">
              <span class="reader-only" id="timer-label">
                Time:
              </span>
              <span aria-labelled-by="timer-label" function="timer">
                <template v-if="!timeDetail">
                  <em>{{ minutesLeft(timeObj) }} </em>
                  <span>minutes left</span>
                </template>
                <template v-if="timeDetail">
                  <em>{{ timeObj.m }}:{{ timeObj.s }}</em>
                  <span> left</span>
                </template>`
              </span>
            </template>
            <template v-else>
              <span> Click to show time </span>
            </template>

          </p>
        </template>
        <template v-slot:finish/>
      </countdown>
      <div :class="[$route.fullPath.includes('listening') ? 'show' : 'hide']" id="audio-control" >
        <div id="slider" />
        <input type="hidden" id="amount" value="50" />
        <input value="test-content/Audio_OGG-1.ogg" type="hidden" id="main-audio-content" />
      </div>
      <ul id="banner-controls" role="toolbar" :class="{show : $route.path.endsWith('main')}" style="display: none;">
        <li class="floatingTool">
          <button class="settingsLink" style="display: none;">Settings</button>
        </li>
        <li class="floatingTool">
          <button class="helpLink" @click="show(`help`)" style="display: inline-flex">
            Help<span> </span>
          </button>
        </li>
        <li class="floatingTool">
          <button class="lockLink" @click="pause">Hide</button>
        </li>
      </ul>
    </div>
    <popup-hide v-if="hide" @close="resume"/>
    <popup-help v-if="help" @close="close(`help`)"/>
  </div>
</template>

<script>
import PopupHide from "@/views/components/global/popupHide";
import PopupHelp from "@/views/components/global/popupHelp";
import {bus} from "@/main";

export default {
  name: "banner",
  components: {PopupHelp, PopupHide},
  data() {
    return {
      hide: false,
      help: false,
      countdown: true,
      timeDetail: false
    }
  },
  created() {
    let thiz = this
    this.$nextTick(() => {
      bus.$on('startCountdown', this.start)
      $('#slider').slider({
        range: 'max',
        value: 50,
        animate: true,
        slide () {
          let val = $( this ).slider( "value" ) / 100;
          val = val > 0.06 ? val : 0;
          thiz.$store.dispatch("app/handleChangeVolume", val)
          bus.$emit('changeVolume')
        }
      });
    })
  },
  methods: {
    show(type) {
      this[type] = true
    },
    close(type) {
      this[type] = false
    },
    start() {
      this.$refs.countdown.startCountdown(true)
      this.$store.dispatch("app/handleChangeTestEnded", false)
    },
    resume() {
      this.hide = false
      this.$refs.countdown.startCountdown()
    },
    pause() {
      this.hide = true
      this.$refs.countdown.pauseCountdown()
    },
    finish() {
      this.$store.dispatch("app/handleChangeTestEnded", true)
    },
    minutesLeft(timeObj) {
      return timeObj.s > 0 ? ('0' + (Number(timeObj.m) + 1)).slice(-2) : timeObj.m
    }
  }
}
</script>


