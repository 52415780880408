<template>
  <div class="popup">
    <div class="dialogue-overlay hideScreen" style="display: block;"></div>
    <div class="dialogue-wrapper"
         style="position: absolute; display: block; outline: none; top: 0px; z-index: 9999;">
      <div class="box2 nontest">
        <div class="title hasicon">Screen hidden
          <div class="close" @click="close"></div>
          <img src="@assets/images/main/screen.png" width="48" height="48"/></div>
        <div class="content"><p>
          <p>Your answers have been stored.</p>
          <p>Please note that the clock is still running. The time has not been paused.</p>
          <p>If you wish to leave the room, please tell your invigilator.</p>
          <p>Click the button below to go back to your test.</p>
          <div class="textCentre">
            <button class="button" @click="close">Resume test</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "popupHide",
  methods: {
    close() {
      this.$emit("close")
    }
  }
}
</script>

<style scoped>

</style>
