import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import common from "@/mixins/common";
import rangy from 'rangy'
import VueCompositionAPI from '@vue/composition-api'
import vueAwesomeCountdown from 'vue-awesome-countdown'

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(vueAwesomeCountdown, 'vac')

Object.defineProperty(Vue.prototype, '$rangy', { value: rangy });
require('rangy/lib/rangy-highlighter')
require('rangy/lib/rangy-core')
require('rangy/lib/rangy-classapplier')
require('rangy/lib/rangy-serializer')
require('rangy/lib/rangy-selectionsaverestore')
require('rangy/lib/rangy-textrange')
window.$ = window.jQuery = require('jquery');

require('jquery-ui/ui/widgets/draggable')
require('jquery-ui/ui/widgets/droppable')
require('jquery-ui/ui/widgets/slider')
require('jquery-contextmenu')
export const bus = new Vue();

new Vue({
    router,
    store,
    mixins: [common],
    render: h => h(App),
}).$mount('#app')
