// import { $themeBreakpoints } from '@themeConfig'

export default {
    namespaced: true,
    state: {
        volume: 0.5,
        isTestEnded: false,
        windowWidth: 0,
        shallShowOverlay: false,
    },
    getters: {
        volume: state => state.volume,
        isTestEnded: state => state.isTestEnded
        // currentBreakPoint: state => {
        //     const { windowWidth } = state
        //     if (windowWidth >= $themeBreakpoints.xl) return 'xl'
        //     if (windowWidth >= $themeBreakpoints.lg) return 'lg'
        //     if (windowWidth >= $themeBreakpoints.md) return 'md'
        //     if (windowWidth >= $themeBreakpoints.sm) return 'sm'
        //     return 'xs'
        // },
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
        },
        UPDATE_VOLUME(state, val) {
            state.volume = val
        },
        UPDATE_TEST_ENDED(state, val) {
            state.isTestEnded = val
        }
    },
    actions: {
        handleChangeVolume(context, newStatus) {
            context.commit("UPDATE_VOLUME", newStatus)
        },
        handleChangeTestEnded(context, newStatus) {
            context.commit("UPDATE_TEST_ENDED", newStatus)
        }
    },
}
