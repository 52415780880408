import Vue from 'vue'
import Vuex from 'vuex'

// Declare modules here
import app from './app'
import appConfig from './app-config'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig
    },
    strict: process.env.DEV,
})
