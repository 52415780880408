import {bus} from "@/main";

export default {
    data() {
        return {
            methodsCallback: [],
        }
    },
    methods: {
        changeQuest(quesId) {
            bus.$emit('changeQuest', quesId)
        },
    }
}
