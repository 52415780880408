<template>
  <HorizontalLayout></HorizontalLayout>
</template>

<script>
import HorizontalLayout from "@/layouts/HorizontalLayout";

export default {
  name: 'App',
  components: {
    HorizontalLayout,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
