export default [
    {
        path: '/apps/take-test',
        name: 'apps-take-test',
        component: () => import('@/views/apps/take-test/TakeTest.vue'),
    },
    {
        path: '/apps/academic-writing/confirm',
        name: 'apps-academic-writing',
        component: () => import('@/views/apps/academic-writing/ConfirmInformation.vue'),
    },
    {
        path: '/apps/academic-reading/confirm',
        name: 'apps-academic-reading',
        component: () => import('@/views/apps/academic-reading/ConfirmInformation.vue'),
    },
    {
        path: '/apps/academic-listening/confirm',
        name: 'apps-academic-listening',
        component: () => import('@/views/apps/academic-listening/ConfirmInformation.vue'),
    },
    {
        path: '/apps/academic-writing/instruction',
        name: 'apps-academic-writing-instruction',
        component: () => import('@/views/apps/academic-writing/WritingInstruction.vue'),
    },
    {
        path: '/apps/academic-reading/instruction',
        name: 'apps-academic-reading-instruction',
        component: () => import('@/views/apps/academic-reading/ReadingInstruction.vue'),
    },
    {
        path: '/apps/academic-listening/instruction',
        name: 'apps-academic-listening-instruction',
        component: () => import('@/views/apps/academic-listening/ListeningInstruction.vue'),
    },
    {
        path: '/apps/academic-writing/main',
        name: 'apps-academic-writing-main',
        component: () => import('@/views/apps/academic-writing/WritingMain.vue'),
    },
    {
        path: '/apps/academic-reading/main',
        name: 'apps-academic-reading-main',
        component: () => import('@/views/apps/academic-reading/ReadingMain.vue'),
    },
    {
        path: '/apps/academic-listening/main',
        name: 'apps-academic-listening-main',
        component: () => import('@/views/apps/academic-listening/ListeningMain.vue'),
    },


]
