<template>
  <div>
    <banner/>
    <router-view></router-view>
  </div>
</template>

<script>

import Banner from "@/views/components/global/banner";
export default {
  name: "HorizontalLayout",
  components: {
    Banner,
  }
}
</script>

<style>
/* Reset most default properties. */
* {
  margin: 0;
  border: 0;
  font: inherit;
  font-size: 1em;
  line-height: inherit;
  text-decoration: inherit;
  background: transparent;
  color: inherit;
  /*list-style-type: none;*/
}

/* Reset padding for everything except select, option.
 * This rule would cause select boxes to break on Firefox. */
:not(select):not(option) {
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.429em; /* 20px at font-size: 14px */
  visibility: hidden; /* hide when test is loading */
  background-color:#fff;
}

body.visible {
  visibility: visible; /* show when test is loaded */
  background-color: #dbe5f5;
}
[role=main] {
  /*min-height: 508px;*/
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid transparent;
  background-image: url("../assets/images/main/background.png");
  background-repeat: repeat-x;
}
.show {
  display: block !important;
}
.hide {
  display: none !important;
}
#mainContent {
  height: calc(100vh - 200px);
}
div[author-class~=container] {
  height: calc(100% - 30px);
}
div[author-class~=left], div[author-class~=right] {
  height: 100%;
}
div[author-class~=presentation-fullHeight]{
  height: calc(100% - 2.6em);
}
div[author-class~=presentation-fullHeight] > textarea{
  height: calc(100% - 20px);
}
</style>
<style src="@assets/css/jquery-ui.css"/>
<style src="@assets/css/access.css"/>
<style src="@assets/css/banner.css"/>
<style src="@assets/css/navigation.css"/>
<style src="@assets/css/tools.css"/>
<style src="@assets/css/main.css"/>
<style src="@assets/css/item.css"/>
<style src="@assets/css/calc.css"/>
<style src="@assets/css/chart.css"/>
<style src="@assets/css/notepad.css"/>
<style src="@assets/css/instructions.css"/>
<style src="@assets/css/jquery.css"/>
<style src="@assets/css/custom.css"/>
