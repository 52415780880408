<template>
  <div class="popup">
    <div class="dialogue-overlay hideScreen" style="display: block;"></div>
    <div xmlns="http://www.w3.org/1999/xhtml" class="dialogue-wrapper ui-draggable"
         style="position: absolute; display: block; outline: none; top: 0px; z-index: 9999;">
      <div class="box2 nontest">
        <div class="title hasicon">Help
          <div class="close" @click="close"></div>
          <img src="@assets/images/main/access.png" width="48" height="48"/></div>
        <div class="content"><p></p>
          <div class="preTestInfo" data-instructionsControls="true">
            <div data-accessControls="true" class="clearfix">
              <div class="tab theme">
                <ul class="tabs">
                  <li :class="{ active : active_name === 'Information' }">
                    <a href="#tab0" @click="() => this.active_name = 'Information'">Information</a>
                  </li>
                  <li :class="{ active : active_name === 'Test help' }">
                    <a href="#tab1" @click="() => this.active_name = 'Test help'">Test help</a>
                  </li>
                  <li :class="{ active : active_name === 'Task help' }">
                    <a href="#tab2" @click="() => this.active_name = 'Task help'">Task help</a>
                  </li>
                </ul>
              </div>
              <div class="tabbed testInfo" >
                <div id="tab0" class="tabpanel" :class="{ active : active_name === 'Information' }">
                  <div class="instructionPanel">
                    <h2>INSTRUCTIONS TO CANDIDATES</h2>
                    <ul>
                      <li>Answer <b>both</b> parts.</li>
                      <li>You can change your answers at any time during the test.</li>
                    </ul>
                    <h2>INFORMATION FOR CANDIDATES</h2>
                    <ul>
                      <li>There are two parts in this test.</li>
                      <li>Part 2 contributes twice as much as Part 1 to the writing score.</li>
                      <li>The test clock will show you when there are 10 minutes and 5 minutes remaining.</li>
                    </ul>
                  </div>
                </div>
                <div id="tab1" class="tabpanel" :class="{ active : active_name === 'Test help' }">
                  <div class="instructionPanel">
                    <h2>At the top of the screen you can see:</h2>
                    <img src="@assets/images/instructions/screenCandidate.png" alt="candidate information" width="186"
                         height="27" class="outline"/>
                    <p>Your name and candidate number.</p>
                    <img src="@assets/images/instructions/screenClock.png" alt="candidate information" width="137" height="27"
                         class="outline"/>
                    <p>A clock, which tells you how much time you have left. When you hover over the time you can see
                      the seconds.</p>
                    <hr/>
                    <table>
                      <tr>
                        <td class="icon help">
												<span class="toolbarButtonIcon noPointer">Help
													<span> </span>
												</span>
                        </td>
                        <td>
                          <span>Click to view the help.</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="icon">
                          <span class="toolbarButtonIcon noPointer">Settings</span>
                        </td>
                        <td>
                          <span>Click to change your screen settings.</span>
                        </td>
                      </tr>
                      <!--<tr>
                        <td class="icon">
                          <span class="toolbarButtonIcon noPointer">Notepad</span>
                        </td>
                        <td>
                          <span>Click to open on-screen notepad (anything you write in the notepad will be deleted at the end of the test).</span>
                        </td>
                      </tr>-->
                      <tr>
                        <td class="icon lock">
                          <span class="toolbarButtonIcon noPointer">Hide</span>
                        </td>
                        <td>
                          <span>Click to hide the screen content temporarily.</span>
                        </td>
                      </tr>
                    </table>
                    <hr/>
                    <h2>Navigation</h2>
                    <p>At the bottom of the screen you can see the navigation bar</p>
                    <img src="@assets/images/instructions/screenNav.png" alt="navigation bar" width="640" height="53"
                         class="outline"/>
                    <p>Click on a number to go to that question.</p>
                    <table>
                      <tr>
                        <td>
                          <img src="@assets/images/navigation/next-question-button.png" alt="next question button" width="56"
                               height="56"/>
                        </td>
                        <td>Click to go to the next question.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/navigation/previous-question-button.png" alt="previous question button"
                               width="56" height="56"/>
                        </td>
                        <td>Click to go to the previous question.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/instructions/screenReview.png" alt="review" width="68" height="21"
                               class="outline"/>
                        </td>
                        <td>Click if you want to look at this question again later. The question number in the
                          navigation bar will turn into a circle.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/navigation/iconMinimise.png" alt="minimise navigation" width="27"
                               height="28"/>
                        </td>
                        <td>Click to make the navigation smaller.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/navigation/iconMaximise.png" alt="maximise navigation" width="27"
                               height="28"/>
                        </td>
                        <td>Click to make the navigation bigger.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/instructions/screenNavCurrent.png" alt="current question" width="44"
                               height="43" class="outline"/>
                        </td>
                        <td>The blue highlighting shows the question you are looking at.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/instructions/screenNavAnswered.png" alt="question answered" width="44"
                               height="43" class="outline"/>
                        </td>
                        <td>The underlining shows that you have answered this question.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/instructions/screenNavReview.png" alt="question marked for review" width="44"
                               height="43" class="outline"/>
                        </td>
                        <td>The circle shows that you want to look at this question again (marked for review).</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/instructions/screenNavNormal.png" alt="question marked for review" width="38"
                               height="38" class="outline"/>
                        </td>
                        <td>The black highlighting shows that you have not answered the question.</td>
                      </tr>
                    </table>
                    <hr/>
                    <h2>Highlighting</h2>
                    <table>
                      <caption>To highlight something in the test:</caption>
                      <tr>
                        <th colspan="2">
                          <p>Select the text you want to highlight using the mouse.
                            <br/>
                            <b>Right click</b>
                            over the text.</p>
                          <img src="@assets/images/instructions/screenRightClick.png" alt="right click menu" width="265"
                               height="106" class="outline"/>
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/instructions/screenRightHighlight.png" alt="highlight" width="120"
                               height="21" class="outline"/>
                        </td>
                        <td>Click to highlight the text you have selected.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="@assets/images/instructions/screenRightNotes.png" alt="notes" width="120" height="21"
                               class="outline"/>
                        </td>
                        <td>Click to highlight the text you have selected and to add notes about what you have
                          highlighted.
                        </td>
                      </tr>
                    </table>
                    <hr/>
                    <h2>Notes</h2>
                    <p>To close the notes click on X in the top right. To view the notes right-click on the highlighted
                      text
                      (anything you write in Notes will be deleted at the end of the test).</p>
                    <img src="@assets/images/instructions/screenNotes.png" alt="notes" width="265" height="317"
                         class="outline"/>
                    <p>You can locate those areas of highlighted text containing notes by hovering over each highlighted
                      text. If a small orange box appears the highlighted text contains notes. </p>
                    <img src="@assets/images/instructions/screenNotesIcon.png" alt="notes" width="304" height="66"
                         class="outline"/>
                    <p>To clear your highlighting right click on the highlighted text.</p>
                    <img src="@assets/images/instructions/screenRightClickHighlight.png" alt="right click menu" width="196"
                         height="110" class="outline"/>
                    <table>
                      <tr>
                        <td>
                          <img src="@assets/images/instructions/screenRightClear.png" alt="clear" width="120" height="21"
                               class="outline"/>
                        </td>
                        <td>Click to clear the highlighting. This will also clear any notes you have made.</td>
                      </tr>
                      <tr>
                        <td><img src="@assets/images/instructions/screenRightClearAll.png" alt="clear" width="120" height="21"
                                 class="outline"/></td>
                        <td>Click to clear all highlighting including notes.</td>
                      </tr>
                    </table>
                    <hr/>
                    <h2>Copy and paste</h2>
                    <p>When writing your response you can use the following keyboard shortcuts:</p>
                    <p>
                      <strong>
                        <i>Ctrl + C</i>
                      </strong>
                      to copy what you have written and selected.
                      <br/>
                      <strong>
                        <i>Ctrl + V</i>
                      </strong>
                      to paste what you have copied.</p>
                  </div>
                </div>
                <div id="tab2" class="tabpanel" :class="{ active : active_name === 'Task help' }">
                  <div class="instructionPanel contextHelp">
                    <p>To choose a question click on the question number at the bottom of the screen.</p>
                    <hr/>
                    <h2>Part 1 and Part 2</h2>
                    <p>Write your answer in the space on the right side of the screen.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="textCentre">
            <button class="button" @click="close">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "popupHelp",
  data () {
    return {
      active_name: 'Information'
    }
  },
  methods: {
    close() {
      this.$emit("close")
    }
  }
}
</script>

<style scoped>

</style>
